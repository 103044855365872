<template>
  <a-row v-if="isLoading" class="content-fix" type="flex">
    <a-col class="flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="16" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Сумма: </a-col>
            <a-col :span="6" class="sm-mb">
              <a-input
                v-model="form['value_' + getKey]"
                type="text"
                name="value"
                placeholder="Введите сумму"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3" class="form_title" style="padding-top: 4px">
              Изображение:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <div v-if="image_url" class="committee-image icon-bg-color">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Позиция: </a-col>
            <a-col :span="3" class="sm-mb">
              <a-input
                v-model="form.position"
                type="number"
                name="position"
                placeholder="Введите сумма"
              />
            </a-col>
          </a-row>
          <a-row style="margin-top: 25px">
            <a-col :span="3" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Отмена
              </a-button>
              <a-button
                :loading="pending"
                type="primary"
                @click="submitUsefullLinks"
              >
                Сохранить
              </a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
export default {
  components: {},
  data: () => {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        value: "",
        type: 2,
        photo: null,
        logo: null
      },
      fileList: [],
      langTab: "oz",
      image: null,
      pending: false,
      image_url: "",
      isLoading: false
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
    // getUrl() {
    //   return this.form.link
    // }
  },
  // watch: {
  //   getUrl(val) {
  //     console.log("form.url", val)
  //   }
  // },
  async created() {
    this.isLoading = false
    const form = await this.$store.dispatch(
      "reportline/fetchReportlineById",
      this.$route.params.id
    )
    this.form = { ...form, value: form.value }
    this.image_url = form.logo
    this.isLoading = true
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submitUsefullLinks() {
      this.pending = true
      const id = this.$route.params.id
      try {
        let form = cloneDeep(this.form)
        form = {
          ...form
        }
        if (typeof form.logo === "string") {
          delete form.logo
        }
        let f = new FormData()
        Object.keys(form).forEach((key) => {
          form[key] && f.append(key, form[key])
          // f === "logo" && f.append(key, this.form[key], this.form[key].name)
          key === "is_active" && f.append(key, form[key])
        })
        let res = await this.$api.patch(
          `/admin/common/reportline/${id}/update/`,
          f
        )
        if (res && res.status === 200) {
          this.$message.success("Успешно сохранено")
          this.$router.go(-1)
        } else {
          this.$message.error("Ошибка при сохранении")
        }
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }

      this.pending = false
    },
    handleImages(arg) {
      this.fileList = arg
    },
    async removeItem() {
      await this.remove(this.data.id)
      this.$message.success("Успешно удалена")
      this.$router.push({ name: "reportline" })
    },
    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0], "dfhdjfhjdfhdjfdfhd")
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.logo = e.target.files[0]
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.icon-bg-color > img {
  background: #2e3740;
  padding: 5px;
  border-radius: 5px;
  max-height: 200px;
}
</style>
